import {DropDownGeneric} from "../../../common/form/DropDownGeneric";
import React from "react";
import {ActivationAlgo, MathEx} from "predictagram-lib";
import Criteria = ActivationAlgo.Criteria;
import ComboCondCompare = ActivationAlgo.ComboCondCompare;

export class Filters {


  public static valuesScores = [
    {label:'All', value: null},
    ...(()=>{
      const dataRange = [.05, .1, .15, .2, .25, .3, .4, .5, .6, .7, .8, .9, 1.0];
      return [...dataRange, 0, ...dataRange.map(v=>v*-1)].sort((a,b)=>b-a).map(i=>{
        return {label: `${MathEx.round(i, 2)}%`, value: MathEx.round(i/100,4) as any}
      })
    })(),
  ];
  public static valuesTrades = [
    {label:'All', value: null},
    ...(()=>{
      const data = [];
      for (let i = 1;i<=50;i+=1) {
        data.push({label: `${i}`, value: i as any});
      }
      return data;
    })(),
  ];

  public static hours(name:string, label:string, namePrefix:string='') {
    return <div className="form-group" key={name}>
      <DropDownGeneric className={"horizontal"}  name={`${namePrefix}${name}`} multiple={true} label={label} options={[9,10,11,12,13,14,15].map(v=>{return {label:v,value:v}})}/>
    </div>
  }

  public static criteriaSubform(namePrefix:string) {
    const supported = ActivationAlgo.SUPPORTED_CRITERIA;
    return <div className="d-flex flex-column gap-2">
      <>
        {supported.map(c=>{
          if ([Criteria.CDL_COLOR, Criteria.PREV_CDL_COLOR].includes(c)) {
            return <div className="form-group" key={c}>
              <DropDownGeneric className={'horizontal'} multiple={true} name={`${namePrefix}${c}`} label={c} options={[1,-1].map(v=>{return {label:v||'All',value:v}})}/>
            </div>
          }
          if ([Criteria.CONSECUTIVE_COLORS].includes(c)) {
            return <div className="form-group" key={c}><DropDownGeneric name={`${namePrefix}${c}`} label={c} options={[null,1,2,3,4,5,6,7,8,9,10].map(v=>{return {label:v||'All',value:v}})}/></div>
          }
          if ([Criteria.STOCK_GAP_LAST_10_POINTS, Criteria.STOCK_GAP_LAST_2_DAYS_NEXT_DAY].includes(c)) {
            return <div className="form-group" key={c}><DropDownGeneric name={`${namePrefix}${c}`} label={c} options={Filters.valuesScores}/></div>
          }
          return  <div className="form-group" key={c}>
            <DropDownGeneric className={'horizontal'} multiple={true} name={`${namePrefix}${c}`} label={c} options={[2,1,-1,-2].map(v=>{return {label:v||'All',value:v}})}/>
          </div>
        })}

        {/*{[*/}
        {/*  Criteria.CDL_COLOR, Criteria.PREV_CDL_COLOR*/}
        {/*].map(c=><div className="form-group" key={c}><DropDownGeneric name={`${namePrefix}${c}`} label={c} options={[null,1,-1].map(v=>{return {label:v||'All',value:v}})}/></div>)}*/}

        {/*{[*/}
        {/*  Criteria.CONSECUTIVE_COLORS*/}
        {/*].map(c=><div className="form-group" key={c}><DropDownGeneric name={`${namePrefix}${c}`} label={c} options={[null,1,2,3,4,5,6,7,8,9,10].map(v=>{return {label:v||'All',value:v}})}/></div>)}*/}

        {/*{[*/}
        {/*  Criteria.STOCK_GAP_LAST_10_POINTS,*/}
        {/*  Criteria.STOCK_GAP_LAST_2_DAYS_NEXT_DAY,*/}
        {/*].map(c=><div className="form-group" key={c}><DropDownGeneric name={`${namePrefix}${c}`} label={c} options={Filters.valuesScores}/></div>)}*/}

        {/*{[*/}
        {/*  Criteria.SMA120_DIST_GROUP,*/}
        {/*  Criteria.VWAP_DIST_GROUP,*/}
        {/*  Criteria.EMA6_DIST_GROUP,*/}
        {/*  Criteria.EMA12_DIST_GROUP,*/}
        {/*  Criteria.EMA26_DIST_GROUP,*/}
        {/*  Criteria.EMA120_DIST_GROUP,*/}
        {/*  Criteria.CDL_COLOR_GROUP,*/}
        {/*  Criteria.PREV_CDL_COLOR_GROUP,*/}
        {/*  Criteria.STOCK_GAP_LAST_10_POINTS_GROUP,*/}
        {/*  Criteria.STOCK_GAP_LAST_5_POINTS_GROUP,*/}
        {/*  Criteria.STOCK_GAP_LAST_2_DAYS_NEXT_DAY_GROUP,*/}
        {/*  Criteria.CONSECUTIVE_COLORS_GROUP,*/}
        {/*  Criteria.EMA6_DIST_NEXT_DAY_GROUP,*/}
        {/*  Criteria.STOCK_GAP_OPEN_PREV_CLOSE_NEXT_DAY_GROUP,*/}
        {/*  Criteria.HIGH_LOW_NEXT_DAY_GROUP,*/}
        {/*  Criteria.VOLUME_GROUP,*/}
        {/*  Criteria.PREV_VOLUME_GROUP,*/}
        {/*].map((c)=>*/}
        {/*  <div className="form-group" key={c}>*/}
        {/*    <DropDownGeneric className={'horizontal'} multiple={true} name={`${namePrefix}${c}`} label={c} options={[2,1,-1,-2].map(v=>{return {label:v||'All',value:v}})}/>*/}
        {/*  </div>*/}
        {/*)}*/}
      </>
    </div>
  }

  public static FormCondition:React.FunctionComponent<{name: string, label: string, values:{label:string,value:any}[], skipOr?:boolean}> =(
    {name, label, values, skipOr}
  ) => {

    const opts = [
      {label: 'All', value: null},
      ...Object.values(ComboCondCompare).map(v=>{return {label:v,value:v}})
    ];

    return (
      <div style={{border:"1px solid #000"}}>
        {label}
        <div className="d-flex align-items-end">
          <div className="d-flex flex-column gap-3" style={{width:"30px"}}>
          </div>
          <div className="d-flex flex-column gap-4" style={{width:'100px'}}>
            <div className="form-group"><DropDownGeneric label={''} name={`${name}[0].compare`} options={opts} /></div>
          </div>
          <div className="d-flex flex-column gap-3"  style={{width:'120px',marginLeft:'20px'}}>
            <div className="form-group"><DropDownGeneric label={'Value'} name={`${name}[0].value`} options={values} /></div>
          </div>
        </div>
        {!skipOr &&
         <div className="d-flex align-items-end">
           <div className="d-flex flex-column gap-3" style={{width: "30px"}}>
             OR {/* OR is default in backend*/}
             {/*<input type="hidden" readOnly={true} value='OR' size={2}  name={`${name}[1].cond`} />*/}
           </div>
           <div className="d-flex flex-column gap-3" style={{width: '100px'}}>
             <div className="form-group"><DropDownGeneric label={''} name={`${name}[1].compare`} options={opts}/></div>
           </div>
           <div className="d-flex flex-column gap-3" style={{width: '120px', marginLeft: '20px'}}>
             <div className="form-group"><DropDownGeneric label={'Value'} name={`${name}[1].value`} options={values}/>
             </div>
           </div>
         </div>
        }

      </div>
    )
  }
}
