import {DateHelper} from "./date.helper";
import {DateEx} from "./date.ex";
import {MathEx} from "./math.ex";
import {NewYorkStockHelper} from "../stock/new-york-stock.helper";
import {IStockDay, StockDirectionType} from "../interface";

export class StockHelper {

    /**
     * Next working hours, include current only if isPremarket
     * @param now
     */
    public static nextWorkingHours(now = new Date()) {
        const secs = new DateEx(now).getTimeSec();
        const currDate = NewYorkStockHelper.getIfIsTradingDay(secs);
        const d = currDate ?
                  ( currDate.isPremarket() ? currDate : currDate.nextDay()):
                    NewYorkStockHelper.findTradingDay(secs, 1);
        // let d = new Date(now);
        // if (this.isTradingDay(now)) {
        //     if (!this.isPreMarketHours(now)) {
        //         d = this.getNextTradingDay(d);
        //     }
        // } else {
        //     d = this.getNextTradingDay(d);
        // }
        return this.workingHours(new Date(d.now()*1000));
    }

    /**
     *
     * @param now it's time regardless time zone, assume it's Eastern Time using secs
     */
    public static workingHours(now = new Date()) {
        // @TODO: it returns now hours for not working days too and is used in many places,
        //  have to continue to support for now
        const day = NewYorkStockHelper.getIfIsTradingDay(new DateEx(now).getTimeSec());
        let startAt;
        let end;
        const fullOffSetMin = 390;
        if (day) {
            startAt = new DateEx(day.openAt()*1000);
            end = new DateEx(day.closeAt()*1000);
            end.setSeconds(0);
        } else {
            const dtEst = DateHelper.extractDateTimeUs(now);
            const tzOffsetHours = dtEst.utcOffset;
            const to2 = (v: number) => {
                if (v < 10 && v > 0) {
                    return (v < 10) ? '0' + v : v;
                } else {
                    return (v < 10) ? (v < 0 ? '-' : '+') + '0' + (v < 0 ? v * -1 : v) : v;
                }
            }
            const str = `${dtEst.year}-${to2(dtEst.month)}-${to2(dtEst.day)}T09:30:00${to2(tzOffsetHours)}:00`;
            const dStart = new DateEx(str);
            // start.setHours(9-tzOffsetHours-now.getTimezoneOffset()/60);
            // start.setMinutes(30);
            // start.setSeconds(0);
            // start.setMilliseconds(0);
            startAt = dStart;
            end = new DateEx(dStart);
            const offSetMin = StockHelper.isHalfDay(dStart) ? 210 : fullOffSetMin;
            end.setMinutes(end.getMinutes() + offSetMin - 1); // tiingo last minute is 3:59
        }
        return {
            start: startAt,
            end: end,
            end8PM: new DateEx(startAt.getTime() + ((fullOffSetMin - 1) * 60 + 3600 * 4) * 1000),
        };
    }

    /**
     * Includes working hours, before, after hours
     * @param now
     */
    public static fullHours(now = new Date()) {
        const wh = StockHelper.workingHours(now);
        // Tiingo supports -90m, +30m. But it can be several missing (not sequential) minutes for these extra minutes
        // wh.start.setMinutes(wh.start.getMinutes()-90); // 8:00AM EST
        // wh.end.setMinutes(wh.end.getMinutes()+31); // 4:30PM EST
        return {
            start: new DateEx(wh.start.getTime() - 90 * 60 * 1000), // 8:00AM EST
            end: new DateEx(wh.end.getTime() + 31 * 60 * 1000), // 4:30PM EST
            workingHourStart: wh.start,
            workingHourEnd: wh.end,
        };
    }

    public static calcMinMax(avgDeltaPrice: number, dMin: number, dMax: number, currValue: number) {
        const rnd = (val: number, decimal = 0) => {
            if (val == null) {
                return val;
            }
            return MathEx.round(val, decimal);
        }
        const ceil = (val: number, adjust: number) => {
            if (adjust === 1) {
                return Math.ceil(val);
            }
            // const shift = Math.pow(10, 1);
            return Math.ceil(val * adjust) / adjust;
        }
        const floor = (val: number, adjust: number) => {
            if (adjust === 1) {
                return Math.floor(val);
            }
            // const shift = Math.pow(10, 1);
            return Math.floor(val * adjust) / adjust;
        }
        let min: number | null = null;
        let max: number | null = null;
        // @TODO: can be removed
        if (dMin && dMax) {
            const roundTo = dMax - dMin < 2 ? 2 : 1; // dMax>50 ? 0: 1;
            const shiftRaw = Math.max(dMax - currValue, currValue - dMin);
            const maxShift = shiftRaw;
            const range = (avgDeltaPrice || 0) * 5;
            // if chart is already spiky, doesn't add stepSize
            const finalShift = Math.max(maxShift, range);
            min = floor(currValue - finalShift, roundTo);
            max = ceil(currValue + finalShift, roundTo);
            // @TODO: need to try center...
        }
        return { min, max };
    }

    /**
     * returns true if given date is a trading day
     * @param date
     * @returns boolean
     */
    static isTradingDay(date: Date = new Date()): boolean {
        return NewYorkStockHelper.isTradingDay(new DateEx(date).getTimeSec());
        // return !(tz.isWeekend(date) || this.holidays.includes(tz.format(date).yyyymmdd() ))
    }

    static getIfIsTradingDay(secs:number) {
        return NewYorkStockHelper.getIfIsTradingDay(secs);
    }

    static isTradingDayAndPast10AM(now:DateEx, extraMin=3) {
        const nowSec = now.getTimeSec();
        const td = StockHelper.getIfIsTradingDay(nowSec);
        return td && nowSec >= td.openAt()+(30+extraMin)*60; // 30+ min to open time
    }
    /**
     * returns the next trading date from the given date, excluding current one
     * @param date
     * @returns Date
     */
    static getNextTradingDay(date: Date = new Date()): Date {
        const secs = new DateEx(date).getTimeSec();
        const dCurr = NewYorkStockHelper.getIfIsTradingDay(secs);
        const d: IStockDay = dCurr ? dCurr.nextDay(): NewYorkStockHelper.findTradingDay(secs, 1);
        // // lets try the next few days in case of long weekends
        // for (let i=1; i < 6; i++) {
        //   const d = new Date(date);
        //   d.setDate(date.getDate() + i);
        //   if (this.isTradingDay(d)) {
        //     return d;
        //   }
        // }
        //
        // throw Error('date not found in index');
        return new Date(d.now()*1000);
    }

    /**
     * returns true when date given is pre-market hours
     * @param date
     * @returns boolean
     */
    static isPreMarketHours(date: Date = new Date()): boolean {
        return NewYorkStockHelper.getIfIsTradingDay(new DateEx(date).getTimeSec())?.isPremarket()||false;
        // const tm = tz.format(date).hour24MinuteNumber();
        // const isTd = this.isTradingDay(date);
        // return isTd && tm < 930
    }

    /**
     * returns true when date given is post-market hours
     * @param date
     * @returns boolean
     */
    static isPostMarketHours(date: Date = new Date()): boolean {
        return NewYorkStockHelper.getIfIsTradingDay(new DateEx(date).getTimeSec())?.isPostmarket()||false;
    }

    /**
     * returns true when market is open given the date
     * @param date
     * @returns boolean
     */
    static isMarketOpen (date: Date): boolean {
        return NewYorkStockHelper.getIfIsTradingDay(new DateEx(date).getTimeSec())?.isOpen()||false;
        // const nytz = tz.format(date);
        // const tm = nytz.hour24MinuteNumber();
        // const dt = nytz.yyyymmdd();
        // if (tz.isWeekend(date) || this.holidays.includes(dt)) {
        //     return false;
        // }
        //
        // if (tm < 930 || tm >= (this.isHalfDay(dt) ? 1300 : 1600) ) {
        //     return false;
        // }
        //
        // return true;
    }

    /**
     * returns true if the last hour of trading day
     * @param date
     * @returns boolean
     */
    static isLastHalfHour (date: Date): boolean {
        // const nytz = tz.format(date);
        // const dt = nytz.yyyymmdd();
        // const tm = nytz.hour24MinuteNumber();
        const nowSecs = new DateEx(date).getTimeSec();
        const d = NewYorkStockHelper.getIfIsTradingDay(nowSecs);
        if (!d) {
            return false;
        }
        return nowSecs<=d.closeAt() && nowSecs>= d.closeAt()-(60*30-1); // closeAt is hh:59

        // if (this.isHalfDay(date)) {
        //     return tm >= 1230 && tm <= 1300;
        // }
        //
        // if (this.isMarketOpen(date)) {
        //     return tm >= 1530;
        // }
        // return false;
    }

    /**
     * Accepts Date of yyyymmdd EST format
     * @param date
     */
    static isHalfDay(date: Date) {
        return NewYorkStockHelper.getIfIsTradingDay(new DateEx(date).getTimeSec())?.isHalfDay()||false;
        // if (date instanceof Date) {
        //     const nytz = tz.format(date);
        //     date = nytz.yyyymmdd();
        // }
        //
        // return this.halfDays.includes(date);
    }

    /**
     * returns the previous trading day of a given date,
     * current date is excluded from the search (yesterday is start point)
     * returns 12AM time
     *
     * @param fromDate
     * @param counter
     * @returns Date
     */
    static findPreviousTradingDay (fromDate: Date): DateEx {
        const secs = new DateEx(fromDate).getTimeSec();
        const d = NewYorkStockHelper.getIfIsTradingDay(secs);
        const prev = d?d.prevDay():NewYorkStockHelper.findTradingDay(secs, -1);
        return new DateEx(prev.startAt()*1000);
        // const ud = DateHelper.extractDateTimeUs(fromDate);
        // const previous = new Date(fromDate);
        // previous.setHours(previous.getHours()-ud.hour, 0,0,0);// shift hours relatively to local
        // let counter = 0;
        //
        // while(counter<5) {
        //     previous.setHours(previous.getHours() - 24);
        //     // return if it's a trading day
        //     if (this.isTradingDay(previous)) {
        //         return previous;
        //     }
        //     counter++;
        // }
        // throw Error('could not find previous trading day')
    }

    /**
     * Find trading date prev/next, start point is provided date (+shift if applied). Time isn't affected, only date
     *
     * @param fromDate (inclusive)
     * @param inclusive - include current day or not
     * @param searchStepDays - -1 prev search, 1 - next search
     */
    static findTradingDay(fromDate: Date, searchStepDays:-1|1, inclusive:boolean): IStockDay {
        const secs = new DateEx(fromDate).getTimeSec();
        const curr = NewYorkStockHelper.getIfIsTradingDay(secs);
        let day:IStockDay;
        if (inclusive) {
            day = curr ? curr: NewYorkStockHelper.findTradingDay(secs, searchStepDays);
        } else {
            day =  curr ?
                         (searchStepDays===1 ? curr.nextDay() : curr.prevDay()) :
                         NewYorkStockHelper.findTradingDay(secs, searchStepDays);
        }
        return day;
    }

    /**
     * OCC symbol option format
     *
     * @param symbol
     */
    public static parseOptionSymbol(symbol:string) {
        if (!symbol) {
            return null;
        }
        // https://en.wikipedia.org/wiki/Option_symbol#The_OCC_Option_Symbol
        const symbolOptionPattern = /^([A-Z]{1,5})(\d?)([ ]{0,5})(\d{2})(\d{2})(\d{2})([CP])(\d{8})$/;

        const res = symbol.replace(' ', '').match(symbolOptionPattern) as any
        if (!res || res.length!==9) {
            return null;
        }
        const rootSymbol = res[1]
        const optionChainType = res[2]
        const year = res[4]
        const month = res[5]
        const day = res[6]
        const callOrPut = res[7]
        const strikePriceRaw = res[8]
        const strikePrice = parseFloat(strikePriceRaw) / 1000.0

        // Per email, decided to use 4:15 PM EST timezone
        const dateEst = `20${year}-${month}-${day}T16:15`;
        const tzo =  DateHelper.extractDateTimeUs(new Date(dateEst)).utcOffset;
        const dif = tzo >= 0 ? '+' : '-';
        const pad = (num:number)=>(num < 10 ? '0' : '') + num;
        const expStr = `${dateEst}${dif}${pad(Math.abs(tzo))}:00`; // EST timezone;
        const expiration = new Date(expStr);
        type OptionType = {
            symbol: string,
            rootSymbol: string,
            callOrPut: 'C'|'P',
            strikePrice: number,
            expiration: Date,
        }
        return {
            symbol: symbol,
            rootSymbol: rootSymbol,
            optionChainType: optionChainType,
            callOrPut: callOrPut,
            strikePrice: strikePrice,
            expiration: expiration,
            // year: 2000 + parseInt(year, 10),
            // month: parseInt(month, 10),
            // day: parseInt(day, 10),
        } as OptionType;
    }

    /**
     *
     * @param expDate
     * @param expDays array of numbers: 0-sun,1-mon...
     */
    public static isValidOptionExpirationDate(expDate: Date, expDays: number[]) {
        const d = new DateEx(expDate);
        const tradeDay = StockHelper.getIfIsTradingDay(d.getTimeSec()) as IStockDay;
        if (!tradeDay) {
            return false;
        }

        // if (!symbol?.optionExpirationDays || symbol.optionExpirationDays.length===0) {
        //     return true;
        // }

        const dEst = DateHelper.extractDateTimeUs(d);
        if (!expDays || !expDays.length || !expDays.includes(dEst.weekday)) {
            return false;
        }

        return true;
    }

    /**
     * Calculation volatility for provided prices
     *
     * @param prices
     */
    public static calculateVolatility(prices:number[]) {
        // Calculate returns for each interval
        const returns = [];
        for (let i = 1; i < prices.length; i++) {
            returns.push((prices[i] - prices[i - 1]) / prices[i - 1]);
        }

        // Calculate the average returns
        let sum = 0;
        returns.forEach(item => {
            sum += item;
        });
        const avgDailyReturn = sum / returns.length;

        // Calculate the standard deviation of daily returns
        sum = 0;
        returns.forEach(item => {
            sum += Math.pow(item - avgDailyReturn, 2);
        });
        const stdDev = Math.sqrt(sum / returns.length);

        // Calculate the volatility
        const volatility = stdDev * Math.sqrt(returns.length);

        return volatility;
    }

}
