import React, {useState} from "react";
import {DatePickerTzEst} from "../../../common/DatePickerTz";
import {DateEx} from "predictagram-lib";
import {StockHelper} from "../../../../_utils/stock.helper";

const DateElem = (p:{label:string,val?:DateEx,onChange?:(d:DateEx)=>void,format?:string})=>{
  const dateFormat = p?.format||'yyyy-MM-dd';
  const [state,setState] = useState(p.val ? new Date(p.val) : undefined);
  return (<>
    <span className="fw-bold">{p.label}</span>
    <DatePickerTzEst dateFormat={dateFormat}
                     onChange={(date: Date) => {
                       setState(date);
                       if (p.onChange) {
                         p.onChange(new DateEx(date))
                       }
                     }}
                     selected={state}
    />
  </>)
}

export const DateRangeStock: React.FunctionComponent<{
  onChangeStart?:(start:DateEx)=>void,
  onChangeEnd?:(end:DateEx)=>void,
  format?: string,
  start?:DateEx,
  end?:DateEx,
}> = (props) => {


  return (
    <div className="d-flex flex-column">
      <DateElem label='Start Date EST (inclusive)' format={props.format} onChange={props.onChangeStart} val={props.start} />
      <DateElem label='End Date EST (inclusive)' format={props.format} onChange={(d)=>{
        if (props.onChangeEnd) {
          const tradeDate = StockHelper.getIfIsTradingDay(d.getTimeSec());
          // @TODO: think about it more, maybe it's not necessary at all or add as option
          // props.onChangeEnd(new DateEx((tradeDate ? tradeDate.closeAt()-59 : d.getTimeSec())*1000))
          props.onChangeEnd(new DateEx(d.getTime()+(86400*1000-1)));
        }
      }} val={props.end} />

    </div>
  )
}
