export class FileHelper {
  public static downloadBlob(blob:Blob|MediaSource, fileName:string) {
    let anchorElement;
    let href;
    try {
      const href = window.URL.createObjectURL(blob);
      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = fileName;

      document.body.appendChild(anchorElement);
      anchorElement.click();

    } finally {
      if (anchorElement) {
        document.body.removeChild(anchorElement);
      }
      if (href) {
        window.URL.revokeObjectURL(href);
      }
    }
  }
}
