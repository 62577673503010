import React, {useContext, useEffect, useRef, useState} from "react";
import {
  ActivationAlgo,
  AdminReportType, AutoTradeSecurityTypeEnumHelper,
  ISignalAlertActivation,
  IStockSymbolAdmin,
  MathEx,
  SignalAlertPerfTypeEnum,
  SignalAlertPerfTypeEnumHelper,
  StockDirectionType, StockSignalsEnum, StockSignalsHelper,
} from "predictagram-lib";
import {Form, Formik} from "formik";
import {FieldWithError} from "components/common";
import {Spinner} from "components/common/Spinner";
import {MessengerContext, Severity} from "../../common/messenger";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ApiStateImpl, IApiState} from "../../../_constants/APIState";
import {adminApiServiceCommon} from "../../../services/AdminApiService";
import {DropDownGeneric} from "../../common/form/DropDownGeneric";
import {SignalAlertCategoriesDropDown} from "../../common/SignalAlertCategoriesDropDown";
import {UrlHelper} from "../../../_utils/UrlHelper";
import {UtilsHelper} from "predictagram-lib/dist/utils/utils.helper";
import {ActivationAlgoHelper} from "predictagram-lib/dist/analysis/activation.algo";
import {CsvHelper} from "../../../_utils/CsvHelper";
import {AdminReportTypeHelper} from "predictagram-lib/dist/dict/admin-report-type.enum";
import Criteria = ActivationAlgo.Criteria;
import ComboCondCompare = ActivationAlgo.ComboCondCompare;
import IReport = ActivationAlgo.IReport;
import IComboIndicator = ActivationAlgo.IComboIndicator;
import {SymbolsDropDown} from "../common/filters/SymbolsDropDown";
import {Filters} from "../common/activation-combo/Filters";

export enum SubmitModeEnum {
  CREATE = 1,
  UPDATE = 2,
  SAVE_AS_NEW = 3,
}
interface Options extends ISignalAlertActivation {

}

export const ActivationComboPage: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);

  const [searchParams] = useSearchParams();
  const itemId = searchParams.get('id')!==null ? parseInt(searchParams.get('id') as any): null;

  const [initialValues, setInitialValues] = useState<Partial<Options>>();

  const navigate = useNavigate();

  const [state, setState] = useState<IApiState>(ApiStateImpl.IDLE);

  useEffect(() => {
    if (itemId) {
      (async () => {
        const item = await adminApiServiceCommon.getActivationComboById(itemId);
        setInitialValues(item);
      })()
    } else {
      setInitialValues({});
    }
  }, [itemId])

  const submitWrap = async (searchOptions: Options, submitMode: SubmitModeEnum) => {
    try {
      await submit(searchOptions, submitMode);
    } catch (e: any) {
      msgrContext.setMessage({ body: e.message }, true, Severity.FATAL);
      setState(ApiStateImpl.error(e));
    }
  }


  const submit = async (dataIn: Options, submitMode: SubmitModeEnum) => {

    const payload = {
      algoTypeId: dataIn.algoTypeId,
      name: dataIn.name,
      data: dataIn.data,
      notes: dataIn.notes,
      phoneNumber: dataIn.phoneNumber,
    } as Partial<ISignalAlertActivation>;

    // new form or save as
    if ([SubmitModeEnum.CREATE,SubmitModeEnum.SAVE_AS_NEW].includes(submitMode)) {
      const result = await adminApiServiceCommon.createActivationComboById(payload);
      msgrContext.setMessage({ body: 'Created #' + result.id }, true, Severity.NORMAL);
      navigate(UrlHelper.getAdminActivationCombo(result.id));
      return;
    } else
    // update existing
    if (submitMode === SubmitModeEnum.UPDATE) {
      if (itemId === null) {
        msgrContext.setMessage({ body: 'Could not update. Missing id' }, true, Severity.FATAL);
        return;
      }
      const result = await adminApiServiceCommon.updateActivationComboById(itemId, payload);
      msgrContext.setMessage({ body: 'Updated #' + result.id }, true, Severity.NORMAL);
    }

  }

  return (
    <div className="strategy-profit mt-3 d-flex justify-content-center">
      <div className={`d-flex flex-column justify-content-center align-items-start gap-3 'd-block'}`}>
        {initialValues && <ActivationComboForm  initialValues={initialValues} onClick={submitWrap} isEditing={!!itemId} />}
      </div>
    </div>
  );
};



export const ActivationComboForm: React.FunctionComponent<{
  initialValues: Partial<Options>,
  onClick: (search: any, submitMode: SubmitModeEnum) => Promise<void>,
  isEditing: boolean,
}> = ({ initialValues, onClick, isEditing}) => {
  const msgrContext = useContext(MessengerContext);
  const inputValues = UtilsHelper.createCopy(initialValues);
  const defaultValues: Partial<Options> = {};
  let initVals = Object.assign(defaultValues, inputValues) as Options;
  const [submitMode, setSubmitMode] = useState<SubmitModeEnum>(SubmitModeEnum.CREATE);
  const setAlgoType = (st:SignalAlertPerfTypeEnum)=>{
    initVals = Object.assign({}, initialValues, {algoTypeId:st}) as Options;
  }
  //const [algoType, setAlgoType] = useState<SignalAlertPerfTypeEnum>(initVals.algoTypeId||SignalAlertPerfTypeEnum.IND);
  const [filterResult, setFilterResult] = useState<{scoreWAvg:number,trades:number,uniqDays:number}|null>(null);

  const actionType = useRef<'analyze'|'download'|'submit'>('submit');

  const scoreIndFieldList:string[] = ActivationAlgoHelper.indicatorComboInfo().scoreFields;
  const tradeIndFieldList:string[] = ActivationAlgoHelper.indicatorComboInfo().tradeFields;

  const reportType = AdminReportType.SIGNAL_ALERT_INDICATOR_DETAILS;
  const filterReport = async(data:Options)=>{
    const reportsList = await adminApiServiceCommon.getSavedReports({adminReportTypeIds:[reportType]});
    if (!reportsList.length) {
      msgrContext.setMessage({body:'report not found'});
      return;
    }
    const id = reportsList[0].id;
    const reportData = (await adminApiServiceCommon.getSavedReport(id))?.data as IReport[];
    if (!reportData) {
      msgrContext.setMessage({body:'report not found'});
      return;
    }
    const combo = data.data as IComboIndicator;
    // console.debug({combo});
    const filteredData = ActivationAlgoHelper.filterIndicatorReport(reportData, combo);
   return {filteredData, reportId: id};
  }

  const downloadReport = async(dataIn:Options)=>{
    const data = await filterReport(dataIn);
    if (!data) {
      return;
    }
    const fileName = `report-${AdminReportTypeHelper.getNames().get(reportType)}-${data?.reportId}-filtered`;
    CsvHelper.downloadCsv(fileName, data.filteredData);
  }

  const analyzeReport = async(dataIn:Options)=>{
    const data = await filterReport(dataIn);
    if (!data) {
      return;
    }
    let scoreTradesSum = 0;
    let trades = 0;
    const uniqDays = new Set<string>();
    data.filteredData.forEach((row)=>{
      trades+=row.tradesAtDate;
      scoreTradesSum+=(row.tradesAtDate*row.scoreAtDate);
      uniqDays.add(row.date);
    });
    setFilterResult({
      trades: trades,
      scoreWAvg: trades ? MathEx.round(scoreTradesSum/trades, 5) : 0,
      uniqDays: uniqDays.size,
    })

  }

  useEffect(() => {
    setSubmitMode(isEditing ? SubmitModeEnum.UPDATE : SubmitModeEnum.CREATE)
  }, [isEditing])

  // const [ symbols, setSymbols ] = useState<IStockSymbolAdmin[]>([]);
  // useEffect(()=>{
  //   const _load = async () => {
  //     const symbols = await adminApiServiceCommon.getSymbols();
  //     setSymbols(symbols);
  //   }
  //   _load();
  // }, []);


  const onSubmit = async (optsIn: Options, actions: any) => {
    const opts = UtilsHelper.createCopy(optsIn);
    opts.data.alertIds = opts.data.alertIds?.length ?
           (opts.data.alertIds as any as string).split(',').map(v=>+v)||null as any : null as any;
    opts.data.alertIdsExclude = opts.data.alertIdsExclude?.length ?
                         (opts.data.alertIdsExclude as any as string).split(',').map(v=>+v)||null as any : null as any;
    opts.phoneNumber = opts.phoneNumber ? parseInt(opts.phoneNumber as any, 10) : null;
    for (const [k,vIn] of Object.entries(opts.data)) {
      let delKey = false;
      if (vIn===null) {
        delKey = true;
      } else
      if (Array.isArray(vIn)) {
        let v = vIn as any[];
        if (v.length===0) {
          delKey = true;
        } else {
          for ( const [aIndex,av] of Array.from(v.entries()).sort((a,b)=>b[0]-a[0])) {
            let isEmpty = true;
            const vals = typeof av === 'object' ? Object.values(av): [av];
            for (const avk of vals) {
              if (avk!==null) {
                isEmpty = false;
              }
            }

            if (isEmpty) {
              v = v.filter((v,index)=>index!==aIndex);
              // console.debug({aIndex,av,vals,isEmpty,k,v});
            }
          }
          if (v.length===0) {
            delKey = true;
          }
        }
      }
      if (delKey) {
        // @ts-ignore
        delete opts.data[k];
      }
    }

    switch(actionType.current) {
      case 'submit': await onClick(opts, submitMode); break;
      case 'analyze': await analyzeReport(opts); break;
      case 'download': await downloadReport(opts); break;
    }


    actions.setSubmitting(false);
  }
  if (initVals?.data?.alertIds) {
    initVals.data.alertIds = (initVals.data.alertIds as any).join(',');
  }
  if (initVals?.data?.alertIdsExclude) {
    initVals.data.alertIdsExclude = (initVals.data.alertIdsExclude as any).join(',');
  }


  const alertDirectionSubform = ()=>{
    return <div className="form-group">
      <DropDownGeneric options={[
        {label:'All', value: null},
        {label:'UP', value: StockDirectionType.UP},
        {label:'DOWN', value: StockDirectionType.DOWN}
      ]} label="Alert Dir" name="data.alertDirection"/>
    </div>
  }
  const alertCategorySubform = ()=>{
    return <div className="form-group">
      <SignalAlertCategoriesDropDown label={'Alert Cats '} multiple={true} name="data.alertCategory"/>
    </div>
  }
  const alertOpenSignals = ()=>{
    return <div className="form-group">
      <DropDownGeneric multiple={true} name="data.alertOpenSignals" label="Alert Open Signals" options={
        Array.from(StockSignalsHelper.signalsMap().entries()).map(([s,n])=>{return {label:n,value:s}})
      } />
    </div>
  }
  const symbolIdsSubform = ()=>{
    return <div className="form-group">
      <SymbolsDropDown label="Symbols" name="data.symbolIds" multiple={true}/>
    </div>
  }
  const alertIdsSubform = ()=>{
    return <>
      <div className="form-group"><FieldWithError fieldName="data.alertIds" label="Alert Ids Include"  /></div>
      <div className="form-group"><FieldWithError fieldName="data.alertIdsExclude" label="Alert Ids Exclude"  /></div>
    </>
  }

  const runHoursSubform = ()=>{
    return Filters.hours('data.runHours', 'Combo Run Hours')
    // return <div className="form-group">
    //   <DropDownGeneric className={"horizontal"}  name="data.runHours" multiple={true} label="Combo Run Hours" options={[9,10,11,12,13,14,15].map(v=>{return {label:v,value:v}})}/>
    // </div>
  }

  const securitiesSubform = ()=>{
      return <div className="form-group">
        <DropDownGeneric className={"horizontal"} multiple={true} name="data.securityTypes" label="Security Types" options={
          Array.from(AutoTradeSecurityTypeEnumHelper.names.entries()).map(([s,n])=>{return {label:n,value:s}})
        } />
      </div>
  }

  const commonSubform = ()=>{
    return <>
      {runHoursSubform()}
      {securitiesSubform()}
      {symbolIdsSubform()}
      {alertIdsSubform()}
      {alertDirectionSubform()}
      {alertCategorySubform()}
      {alertOpenSignals()}
    </>
  }

  return (
    <div className="activation-combo-form">
      <div className="page-title mb-3">Activation Combo Setup</div>

      <Formik initialValues={initVals} enableReinitialize onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue, isSubmitting }) => {
          const isIndAlgo = initVals.algoTypeId===SignalAlertPerfTypeEnum.IND;
          const isRpWinAlgo = initVals.algoTypeId===SignalAlertPerfTypeEnum.REPEAT_WINNER;
          return <Form>
            <div className="d-flex justify-content-between align-items-end ">
              <div className="form-group">
                <DropDownGeneric onChange={(v)=>setAlgoType(v as any)} options={Array.from(SignalAlertPerfTypeEnumHelper.names().entries()).map(([id,name])=>{return {label:name,value:id}})} label="Algo Type" name="algoTypeId"/>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-end ">
              <div className="form-group">
                <FieldWithError size={25} errors={errors} touched={touched} fieldName="name" label="Name"  />
              </div>
              <div className="form-group">
                <FieldWithError size={35} errors={errors} touched={touched} fieldName="notes" label="Notes" />
              </div>
              <div className="form-group">
                <FieldWithError size={10} errors={errors} touched={touched} fieldName="phoneNumber" label="Phone Number" />
              </div>
            </div>
            <div>&nbsp;</div>
            <div className="d-flex justify-content-between align-items-start gap-4">
              {/*Submform with common and custom options - left side*/}
              <div className="d-flex flex-column gap-4 align-top">
                {commonSubform()}
                {isRpWinAlgo && <>
                  {['scoreAfterHist'].map(sName=>{
                    return (<div key={sName} className="form-group">
                      <Filters.FormCondition name={`data.${sName}`} label={sName} values={Filters.valuesScores}/>
                    </div>)
                  })}
                  {['tradesAfterHist'].map(sName=>{
                    return (<div key={sName} className="form-group">
                      <Filters.FormCondition name={`data.${sName}`} label={sName} values={Filters.valuesTrades}/>
                    </div>)
                  })}
                  {['minProfTradesBefore','minLossTradesBefore'].map(tName=>{
                    return (<div key={tName} className="form-group">
                      <DropDownGeneric name={`data.${tName}`} label={tName + ' (>=)'} options={Filters.valuesTrades}/>
                    </div>)
                  })}
                </>}
                {isIndAlgo && <>
                  {scoreIndFieldList.map(sName=>{
                    return (<div key={sName} className="form-group">
                      <Filters.FormCondition name={`data.${sName}`} label={sName} values={Filters.valuesScores}/>
                    </div>)
                  })}
                  {tradeIndFieldList.map(tName=>{
                    return (<div key={tName} className="form-group">
                      <DropDownGeneric name={`data.${tName}`} label={tName + ' (>=)'} options={Filters.valuesTrades}/>
                    </div>)
                  })}

                  {Filters.hours('data.alertHours', 'Alert Hours')}
                </>}
              </div>
              {(isRpWinAlgo || isIndAlgo) && <>
                {Filters.criteriaSubform('data.')}
              </>}
            </div>


            <div>&nbsp;</div>
            <div className="d-flex justify-content-center align-items-center gap-3">
              {isIndAlgo && <>
              <button type="submit" className="btn btn-primary p-2"  onClick={()=>{actionType.current='analyze'}} >Analyze</button>
              <button type="submit" className="btn btn-primary p-2"  onClick={()=>{actionType.current='download'}}>Download</button>
               </>}
              <div style={{width:'200px'}}></div>

              {isSubmitting && <Spinner minHeight={50} />}
              {isEditing ?
               <div className="d-flex gap-2">
                 <button type="submit" onClick={() => {actionType.current='submit'; setSubmitMode(SubmitModeEnum.UPDATE)}} className="btn btn-primary p-2" disabled={isSubmitting}>Update</button>
                 <button type="submit" onClick={() => {actionType.current='submit'; setSubmitMode(SubmitModeEnum.SAVE_AS_NEW)}} className="btn btn-primary p-2" disabled={isSubmitting}>Save As New</button>
               </div>
                         :
               <button type="submit" onClick={() =>  {actionType.current='submit';setSubmitMode(SubmitModeEnum.CREATE)}} className="btn btn-primary p-2" disabled={isSubmitting}>Create New</button>
              }


            </div>

          </Form>
        }}

      </Formik>
      {filterResult && <>
        <div className="d-flex gap-2">
          <div><b>Score</b>: {MathEx.round(filterResult.scoreWAvg*100, 4)}%</div>
          <div><b>Trades</b>: {filterResult.trades}</div>
          <div><b>Uniq Dates</b>: {filterResult.uniqDays}</div>
        </div>
      </>}
      <div>&nbsp;</div>
      <div>NOTES:
        <p><b>Phone Number</b> should be in international format without "+" prefix and spaces. <br />Number "+1 123 456 7890" should be entered as "11234567890".</p>
      </div>
    </div>
  )
}
