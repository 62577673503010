import React, {useRef, useState} from "react";
import {Form, Formik} from "formik";
import {Filters} from "../../common/activation-combo/Filters";
import {Spinner} from "../../../common/Spinner";
import {MathEx} from "predictagram-lib";
import {adminApiServiceCommon} from "../../../../services/AdminApiService";
import {SignalAlertCategoriesDropDown} from "../../../common/SignalAlertCategoriesDropDown";
import {SymbolsDropDown} from "../../common/filters/SymbolsDropDown";
import {UtilsHelper} from "predictagram-lib/dist/utils/utils.helper";
import {FileHelper} from "../../../../_utils/FileHelper";
import {DateRangeStock} from "../../common/filters/DateRangeStock";
import {DropDownGeneric} from "../../../common/form/DropDownGeneric";

export const AlertTradesList: React.FunctionComponent = () => {
  const actionType = useRef<'analyze'|'download'>('analyze');
  const [analyzedResult, setAnalyzeResult] = useState({trades:0,score:0});

  const onSubmit = async (optsIn: any) => {
    const opts = UtilsHelper.createCopy(optsIn);
    if (opts.columnWhereCond) {
      opts.columnWhereCond = Object.entries(opts.columnWhereCond).map(([name,val])=>{
        const v = val as any;
        return {cond:v[0].compare,value:v[0].value,column:name};
      }).filter(c=>c.value!=null&&c.cond!=null);
    }
    switch(actionType.current) {
      case 'analyze':
        const resAnalyze = await adminApiServiceCommon.analysisAlertTradesSummary({filters:opts})
        setAnalyzeResult(resAnalyze);
      break;
      case 'download':
        const blob = await adminApiServiceCommon.analysisAlertTrades({filters:opts, download:{asCsv:true}}, {
          responseType: 'blob',
        });

        FileHelper.downloadBlob(blob, 'alert-trades-analysis.csv');

        // const link = document.createElement('a');
        // link.download = 'alert-trades-analysis.csv';
        // link.href = 'data:text/csv;charset=utf-8,' + encodeURI(res);
        // link.click();

        break;
    }
  }
  const fields = [
    'tradesYesterday',
    'scoreYesterday',

    'tradesBeforeYesterday',
    'scoreBeforeYesterday',

    'tradesLast7',
    'scoreLast7',

    'tradesLast14',
    'tradesProfitableLast14',
    'scoreLast14',

    'tradesLast30',
    'scoreLast30',

    'tradesLast90',
    'scoreLast90',

    'tradesLast150',
    'scoreLast150',
  ]

  return <>
    <div className="strategy-profit mt-3 d-flex justify-content-center">
      <div className={`d-flex flex-column justify-content-center align-items-start gap-3 'd-block'}`}>
        <div className={'activation-combo-form'} >

    <Formik initialValues={{}} enableReinitialize onSubmit={onSubmit}>
      {({ values, touched, errors, setFieldValue, isSubmitting }) => {
       return <Form>

         <div className="d-flex justify-content-center align-items-center gap-3">
           <button type="submit" className="btn btn-primary p-2"  onClick={()=>{actionType.current='analyze'}}>Analyze</button>
           <button type="submit" className="btn btn-primary p-2"  onClick={()=>{actionType.current='download'}}>Download</button>

           <div style={{width:'200px'}}>
             {isSubmitting && <Spinner minHeight={30} />}
           </div>


             <div className="d-flex gap-2" style={{minWidth:'250px'}}>
               <div><b>Score</b>: {MathEx.round(analyzedResult.score*100, 4)}%</div>
               <div><b>Trades</b>: {analyzedResult?.trades}</div>
             </div>

         </div>
         <div>&nbsp;</div>

         <div className="d-flex justify-content-between align-items-start gap-4">
            <div className="d-flex flex-column gap-4 align-top">
              <div className={"form-group-compact"}>
                <DateRangeStock
                  onChangeStart={d=>setFieldValue('startTime', d.getTimeSec())}
                  onChangeEnd={d=>setFieldValue('endTime', d.getTimeSec()-1)}
                />
              </div>
              <div className="form-group">
                <SignalAlertCategoriesDropDown label={'Alert Cats'} multiple={true} name="signalAlertCategories"/>
              </div>
              <div className="form-group">
                <SymbolsDropDown label="Symbols" name="stockSymbolIds" multiple={true}/>
              </div>
              {Filters.hours('columnWhereIn.openHourEst', 'openHourEst')}
              <div className="form-group">
                <DropDownGeneric options={['alertId','dateEst','openHourEst'].map(v=>{return {label:v,value:v}})} label={<div className="fw-bold">Distinct Columns</div>} name={'columnDistinct'} multiple={true} />
              </div>

            </div>
           <div className="d-flex flex-column gap-4 align-top">
             {fields.map(name=>{
               const vals = name.startsWith('trades') ? Filters.valuesTrades : Filters.valuesScores;
               return <div key={name} className="form-group">
                 <Filters.FormCondition name={`columnWhereCond.${name}`} label={name} values={vals} skipOr={true}/>
               </div>
             })}
           </div>
            {Filters.criteriaSubform('columnWhereIn.')}
          </div>


        </Form>
      }}
    </Formik>

        </div>
      </div>
    </div>
    <div className="my-3 mb-5">
      <hr />
      <p className="fw-bold">NOTES:</p>
      <ul>
        <li>One row is one alert's trade.</li>
        <li><b>openTimeEst</b> - trade's open(entry) EST time.</li>
        <li>Criteria value is relative to <b>openTimeEst</b>. <br/> Sample: <b>openTimeEst</b> is "2025-03-20 13:59:00". Criteria with NextDay prefix is criteria at "2025-03-20 10:00". Criteria without "NextDay" prefix is previous day criteria at "2025-03-19 15:59".</li>
        <li>NextDay criteria is available only for trades after 10:00.</li>
        <li>Criteria is calculated for each stock individually and is appended to each alert's trade.</li>
        <li><b>Distinct Columns</b> - combination of selected columns shouldn't repeat in results data. It's applied AFTER other filters.</li>
      </ul>
    </div>
  </>
}
