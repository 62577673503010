import {AuthenticationService, BaseApiService} from '.';
import { adminAuthenticationService } from '.';
import { config } from 'config';
import { IContest } from 'interfaces/IContest';
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import { ILeague, ITeam } from 'interfaces/league';
import { ILeagueSeason } from 'interfaces/league/ILeagueSeason';
import { IStockHistory } from 'interfaces/IStockHistory';
import { IPredictionAdmin } from 'interfaces/IPrediction';
import {
  AnalysisOutputParams,
  DateEx,
  IQuoteFull,
  IStockSymbolAdmin,
  PredictionTypeEnum,
  StockStatsIntervalEnum as Interval,
  IAutoTradeSetup,
  IAutoTrade,
  IBKRSide,
  AutoTradePositionTypeEnum,
  AutoTradeStatusEnum,
  AutoTradeManagementTypeEnum,
  IbkrDataStructure,
  AnalysisCumulativeChartData,
  AnalysisInputParams,
  IAdminReport,
  Analysis,
  SignalAlertCategoryEnum,
  ActivationAlgo,
  ISignalAlertActivation,
  AdminReportType,
  ISignalAlertActivationDayPerf,
  SignalGroupTypeEnum,
  ISignalGroup,
  SignalAlertPerfTypeEnum,
  AutoTradeSecurityTypeEnum, AutoTradeStatusReasonEnum, AutoTradeSetupStatusEnum, IAutoTradeByCombo
} from 'predictagram-lib';

import { IAward } from 'interfaces/IAward';
import { ISignalAlert } from 'components/admin/analysis/strategy-profit/ISignalAlert';
import {IAutoTradeSetupEx} from "../interfaces/IAutoTradeSetupEx";


class AdminApiService<T> extends BaseApiService<T> { }

class AdminContestApiService extends AdminApiService<IContest> {
  async addUsers(id: number, payload: any): Promise<boolean> {
    const url = `${this.apiBaseUrl}/contest/add-users/${id}`;
    try {
      const response = await axios.post<any>(url, payload, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as boolean
    } catch (error: any) {
      throw Error((error as Error).message);
    }
  }
}

class AdminStockHistoryApiService extends BaseApiService<IStockHistory>{
  async getAll8PM(searchOptions: {
    startTime: number,
    endTime: number,
    symbolName?: string,
  }): Promise<IStockHistory[]> {
    try {
      const response: any = await axios.post<IStockHistory[]>(this.allUrl, {filters:searchOptions}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as IStockHistory[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }
}

class AdminLeagueSeasonApiService extends BaseApiService<ILeagueSeason> { }
class AdminLeagueTeamApiService extends BaseApiService<ITeam> { }
class AdminLeagueApiService extends BaseApiService<ILeague> { }

export interface IPredictionAdminSearchOptions {
  predictionType?: PredictionTypeEnum,
  predictionTypes?: PredictionTypeEnum[],
  symbolNames?: string[],
  startTime: number,
  endTime: number,
  limit?: number,
  userId?: number,
  usenameLike?: string,
  emailLike?: string,
}

class AdminPredictionApiService extends BaseApiService<IPredictionAdmin> {
  async search(searchOptions: IPredictionAdminSearchOptions): Promise<IPredictionAdmin[]> {
    try {
      // @TODO: convert to array in UI
      const type = parseInt(searchOptions.predictionType as any, 10);
      if (type) {
        searchOptions.predictionTypes = [type];
      }
      const response = await axios.post<IPredictionAdmin[] | any>(this.allUrl, { filters: searchOptions }, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as IPredictionAdmin[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async getBarsData(tickerSymbol: string, startDateTime: DateEx, endDateTime: DateEx, cacheOk=false, interval: Interval= Interval.MIN_1): Promise<ReadonlyArray<IQuoteFull>> {

    const apiUrl = `${this.apiBaseUrl}/stock/stats/${interval}`;
    try {
      const response = await axios.post(apiUrl, {filters: {
          symbolName: tickerSymbol,
          startTime: startDateTime.getTimeSec(),
          endTime: endDateTime.getTimeSec(),
        }}, await this.authenticationService.getAuthHeader());
      const d = response.data.data;
      return d;
    } catch (error: any) {
      throw Error((error as Error).message);
    }

  }
}

class AdminAwardApiService extends BaseApiService<IAward> {
  async awardUser(awardId: number, userId: number): Promise<any>{
    const url = `${this.apiBaseUrl}/award/award-user`;
    try {
      const response = await axios.post<any>(url, {awardId, userId}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as boolean
    } catch (error: any) {
      throw Error((error as Error).message);
    }

  }
}

class AdminSignalAlertApiService extends BaseApiService<ISignalAlert> {
  async getAll(withAuth: boolean = true, usePost: boolean = false) {
    const all = await super.getAll(withAuth, true);
    return all.sort((a, b) => b.id - a.id )
  }
}



class AdminAutoTradeSetupService extends BaseApiService<IAutoTradeSetup> {
  async getByIdWithSignalAlert(id:number): Promise<IAutoTradeSetupEx> {
    const data = await adminAutoTradeSetupService.getById(id) as IAutoTradeSetupEx;
    if (data) {
      data.signalAlert = await adminSignalAlertApiService.getById(data.signalAlertId) as ISignalAlert;
    }
    return data;
  }
}

export interface IAutoTradeSetupSearchFilter {
  signalAlertIds?: number[],
  stockSymbolId?: number,
  autoTradeSetupStatusIds?: AutoTradeSetupStatusEnum[],
  autoTradeSecurityTypes?: AutoTradeSecurityTypeEnum[],
  autoTradeSetupIds?: number[]
}

export interface IAutoTradeSearchFilter {
  autoTradeId?: number,
  startTime?: number,
  endTime?: number,
  ibkrSide?: IBKRSide,
  positionType?: AutoTradePositionTypeEnum,
  autoTradeStatusIds?: AutoTradeStatusEnum[],
  autoTradeManagementTypeId?: AutoTradeManagementTypeEnum,
  signalAlertCategories?: SignalAlertCategoryEnum[],
  autoTradeSecurityTypes?: AutoTradeSecurityTypeEnum[],
  autoTradeSecurityTypeId?: AutoTradeSecurityTypeEnum,
  stockSymbolId?: number,
  stockSymbolIds?: number[],
  signalAlertActivationAlgoTypeIds?: SignalAlertPerfTypeEnum[],
  signalAlertActivationComboIds?: number[],
  autoTradeSetupId?: number,
  autoTradePositionTypes?: AutoTradePositionTypeEnum[],
  autoTradeStatusReasons?: AutoTradeStatusReasonEnum[],

}

export interface IAutoTradePrice {
  lastPrice: number,
  askPrice: number,
  bidPrice: number,
  bestTradePrice: number,
}

class AdminAutoTradesService extends BaseApiService<IAutoTrade> {
  async getFilteredData(filters: Partial<IAutoTradeSearchFilter>): Promise<IAutoTrade[]> {
    try {
      const response: any = await axios.post<IAutoTrade[]>(this.allUrl, {filters}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as IAutoTrade[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async getLiveSymbolPrices(symbolId: number): Promise<IAutoTradePrice> {
    const url = `${this.apiBaseUrl}/auto-trade/live-symbol-prices/${symbolId}`;
    try {
      const response: any = await axios.post<IAutoTradePrice>(url, {}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as IAutoTradePrice;

    } catch (error: any) {
      throw Error(error.message);
    }    
  }

}


export interface IAutoTradeLivePrice {
  lastPrice: number,
  askPrice: number,
  bidPrice: number,
}

export interface IIbkrTradePosition {
  quantity: number,
  contractId: number
}

class AdminApiServiceCommon  {

  constructor(
    protected apiBaseUrl:string,
    protected authenticationService: AuthenticationService) {
  }

  protected async queryData(req:()=>Promise<AxiosResponse>) {
    try {
      const response = await req();
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data as any;
    } catch (error: any) {
      throw Error((error as Error).message);
    }
  }

  async getTradeSetups(data?:{filters?:IAutoTradeSetupSearchFilter}): Promise<IAutoTradeSetup[]>{
    const url = `${this.apiBaseUrl}/auto-trade/setups`;
    return (await this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader()))).data;
  }

  async getTradesByScore(filters: Partial<IAutoTradeSearchFilter>): Promise<IAutoTradeByCombo[]>{
    const url = `${this.apiBaseUrl}/auto-trade/trade/combos`;
    return (await this.queryData(async()=>axios.post<any>(url, {filters:filters}, await this.authenticationService.getAuthHeader()))).data;
  }

  async getActivationCombos(data?:{
    signalAlertActivationStatusIds?: ActivationAlgo.StatusEnum[],
    signalAlertActivationAlgoTypeIds?: SignalAlertPerfTypeEnum[],
  }): Promise<ISignalAlertActivation[]>{
    const url = `${this.apiBaseUrl}/signal/alert/activations`;
    return (await this.queryData(async()=>axios.post<any>(url, {filters:data}, await this.authenticationService.getAuthHeader()))).data;
  }

  async getActivationCombosPerformance(data:{
    signalAlertActivationIds?: number[]
  }): Promise<ISignalAlertActivationDayPerf[]>{
    const url = `${this.apiBaseUrl}/signal/alert/activation-perf-history`;
    return (await this.queryData(async()=>axios.post<any>(url, {filters:data}, await this.authenticationService.getAuthHeader()))).data;
  }

  async getActivationComboById(id:number): Promise<ISignalAlertActivation>{
    const url = `${this.apiBaseUrl}/signal/alert/activation/${id}`;
    return (await this.queryData(async()=>axios.get<any>(url, await this.authenticationService.getAuthHeader())));
  }

  async updateActivationComboById(id:number, data:Partial<ISignalAlertActivation>): Promise<ISignalAlertActivation>{
    const url = `${this.apiBaseUrl}/signal/alert/activation/${id}`;
    return (await this.queryData(async()=>axios.put<any>(url, data, await this.authenticationService.getAuthHeader())));
  }

  async deleteActivationComboById(id:number): Promise<boolean>{
    const url = `${this.apiBaseUrl}/signal/alert/activation/${id}`;
    return (await this.queryData(async()=>axios.delete<any>(url, await this.authenticationService.getAuthHeader()))).success===true;
  }

  async createActivationComboById(data:Partial<ISignalAlertActivation>): Promise<ISignalAlertActivation>{
    const url = `${this.apiBaseUrl}/signal/alert/activation`;
    return (await this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader())));
  }


  async getSignalGroups(data:{
    signalGroupType?: SignalGroupTypeEnum,
  }): Promise<ISignalGroup[]>{
    const url = `${this.apiBaseUrl}/signal/groups`;
    return (await this.queryData(async()=>axios.post<any>(url, {filters:data}, await this.authenticationService.getAuthHeader()))).data;
  }

  async getSignalGroupById(id:number): Promise<ISignalGroup>{
    const url = `${this.apiBaseUrl}/signal/group/${id}`;
    return (await this.queryData(async()=>axios.get<any>(url, await this.authenticationService.getAuthHeader())));
  }

  async updateSignalGroupById(id:number, data:Partial<ISignalGroup>): Promise<ISignalAlertActivation>{
    const url = `${this.apiBaseUrl}/signal/group/${id}`;
    return (await this.queryData(async()=>axios.put<any>(url, data, await this.authenticationService.getAuthHeader())));
  }

  async deleteSignalGroupById(id:number): Promise<boolean>{
    const url = `${this.apiBaseUrl}/signal/group/${id}`;
    return (await this.queryData(async()=>axios.delete<any>(url, await this.authenticationService.getAuthHeader()))).success===true;
  }

  async createSignalGroup(data:Partial<ISignalGroup>): Promise<ISignalAlertActivation>{
    const url = `${this.apiBaseUrl}/signal/group`;
    return (await this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader())));
  }

  async addRemoveSignalGroupAlert(groupId:number,alertId:number,action:'add'|'remove'): Promise<boolean>{
    const data = {
      alertId: alertId,
      groupId: groupId,
    };
    const url = `${this.apiBaseUrl}/signal/group/alert/` + action;
    return (await this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader()))).success===true;
  }

  async getSavedReports(filters:{
    adminReportTypeIds?: AdminReportType[],
  }): Promise<IAdminReport[]>{
    const url = `${this.apiBaseUrl}/report/saved-reports`;
    return (await this.queryData(async()=>axios.post<any>(url, {filters:filters}, await this.authenticationService.getAuthHeader()))).data;
  }

  async getSavedReport(id:any): Promise<IAdminReport>{
    const url = `${this.apiBaseUrl}/report/saved-report/${id}`;
    return this.queryData(async()=>axios.get<any>(url, await this.authenticationService.getAuthHeader()));
  }

  async analysisStrategyProfit(data:any): Promise<AnalysisOutputParams>{
    const url = `${this.apiBaseUrl}/analysis/strategy-profit`;
    return this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader()));
  }

  async analysisStrategyProfitDaily(data:any): Promise<any>{
    const url = `${this.apiBaseUrl}/analysis/strategy-profit-daily`;
    return this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader()));
  }

  async analysisStrategyProfitCombined(data:any): Promise<any>{
    const url = `${this.apiBaseUrl}/analysis/strategy-profit-combined`;
    return this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader()));
  }

  async analysisCumulativeChartData(data:AnalysisInputParams): Promise<AnalysisCumulativeChartData>{
    const url = `${this.apiBaseUrl}/analysis/cumulative-chart-data`;
    return this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader()));
  }

  async analysisCumulativeChartDataDay(data:AnalysisInputParams): Promise<Analysis.CumulativeChartDataDay>{
    const url = `${this.apiBaseUrl}/analysis/cumulative-chart-data-day`;
    return this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader()));
  }

  async analysisAlertTradesSummary(params:any): Promise<any> {
    const url = `${this.apiBaseUrl}/report/analysis-alert-trades/summary`;
    return this.queryData(async()=>axios.post<any>(url, params, await this.authenticationService.getAuthHeader()));
  }

  async analysisAlertTrades(params:any, optsIn?:AxiosRequestConfig): Promise<any> {
    const url = `${this.apiBaseUrl}/report/analysis-alert-trades`;
    const opts = await this.authenticationService.getAuthHeader();
    return this.queryData(async()=>axios.post<any>(url, params, Object.assign(opts, optsIn||{})));
  }

  async createSignalAlert(data:any) {
    const url = `${this.apiBaseUrl}/analysis/signal/alert`;
    return this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader()));
  }

  async updateSignalAlert(data:any, id: number) {
    const url = `${this.apiBaseUrl}/analysis/signal/alert/${id}`;
    return this.queryData(async()=>axios.put<any>(url, data, await this.authenticationService.getAuthHeader()));
  }

  async signalAlerts(data:any):Promise<(ISignalAlert&{symbolName:string})[]> {
    const url = `${this.apiBaseUrl}/analysis/signal/alerts`;
    return this.queryData(async()=>(await axios.post<any>(url, data, await this.authenticationService.getAuthHeader())).data||[]);
  }

  async deleteSignalAlert(id:number) {
    const url = `${this.apiBaseUrl}/analysis/signal/alert/${id}`;
    return this.queryData(async()=>axios.delete<any>(url, await this.authenticationService.getAuthHeader()));
  }

  async getSymbols():Promise<IStockSymbolAdmin[]> {
    const url = `${this.apiBaseUrl}/stock/symbols`;
    return this.queryData(async()=>{
      return (await axios.post<any>(url, await this.authenticationService.getAuthHeader())).data
    });
  }

  async createTradeSetup(data: any) {
    const url = `${this.apiBaseUrl}/auto-trade/setup`;
    return this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader()));
  }

  async updateTradeSetup(data:any, id: number) {
    const url = `${this.apiBaseUrl}/auto-trade/setup/${id}`;
    return this.queryData(async()=>axios.put<any>(url, data, await this.authenticationService.getAuthHeader()));
  }

  async deleteTradeSetup(id: number) {
    const url = `${this.apiBaseUrl}/auto-trade/setup/${id}`;
    const result = await this.queryData(async()=>axios.delete<any>(url, await this.authenticationService.getAuthHeader()));
    return !!result?.success;
  }

  async listTradeSetups() {
    const url = `${this.apiBaseUrl}/auto-trade/trades`;
    const data = {}; 
    return this.queryData(async()=>axios.post<any>(url, data, await this.authenticationService.getAuthHeader()));
  }

  async closeTrade(id:number): Promise<boolean> {
    const url = `${this.apiBaseUrl}/auto-trade/trade/close/${id}`;
    const result = await this.queryData(async()=>axios.post(url, {}, await this.authenticationService.getAuthHeader()));
    return !!result?.success;
  }

  async addTrade(id:number) {
    const url = `${this.apiBaseUrl}/auto-trade/trade/add-like/${id}`;
    return this.queryData(async()=>axios.post(url, {}, await this.authenticationService.getAuthHeader()));
  }

  async updateTrade(id:number, data:Partial<IAutoTrade>) {
    const url = `${this.apiBaseUrl}/auto-trade/trade/close/${id}`;
    return this.queryData(async()=>axios.put(url, data, await this.authenticationService.getAuthHeader()));
  }

  async getTradeLivePrices(id:number): Promise<IAutoTradeLivePrice> {
    const url = `${this.apiBaseUrl}/auto-trade/trade/live-prices/${id}`;
    const result = await this.queryData(async()=>axios.post(url, {}, await this.authenticationService.getAuthHeader()));
    return result;
  }

  async getIbkrPositions(): Promise<IIbkrTradePosition[]> {
    const url = `${this.apiBaseUrl}/auto-trade/ibkr/positions`;
    try {
      const response: any = await axios.post<IAutoTrade[]>(url, {}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as IIbkrTradePosition[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async getIbkrAccount(): Promise<IbkrDataStructure> {
    try {
      const url = `${this.apiBaseUrl}/auto-trade/ibkr/accounts`;
      const response: any = await axios.post<any>(url, {}, await this.authenticationService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.code)
      }
      return response.data as IbkrDataStructure;
    } catch (error: any) {
      throw Error(error.message);
    }

  }
}

export interface IAutoTradeStockLimit {
  symbolName: string,
  stockSymbolId: number,
  quantityOpenMax: number | null,
}

class AdminAutoTradeStockLimitService extends BaseApiService<IAutoTradeStockLimit> {
  async getAll(withAuth: boolean = true, usePost: boolean = false) {
    const all = await super.getAll(withAuth, true);
    return all.sort((a, b) => a.symbolName.localeCompare(b.symbolName) )
  }

  async update(item: IAutoTradeStockLimit, stockSymbolId: number) {
    return await super.update(item, stockSymbolId);
  }
}



const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/admin`;
const adminContestApiService: AdminContestApiService = new AdminContestApiService(apiBaseUrl, '/contest', '/contests', adminAuthenticationService);
const adminLeagueApiService: AdminLeagueApiService = new AdminLeagueApiService(apiBaseUrl, '/league', '/leagues', adminAuthenticationService);
const adminLeagueTeamApiService: AdminLeagueTeamApiService = new AdminLeagueTeamApiService(apiBaseUrl, '/league/team', '/league/teams', adminAuthenticationService);
const adminLeagueSeasonApiService: AdminLeagueSeasonApiService = new AdminLeagueSeasonApiService(apiBaseUrl, '/league/season', '/league/seasons', adminAuthenticationService);
const adminStockHistoryApiService: AdminStockHistoryApiService = new AdminStockHistoryApiService(apiBaseUrl, '/stock/history', '/stock/histories', adminAuthenticationService);
const adminPredictionApiService: AdminPredictionApiService = new AdminPredictionApiService(apiBaseUrl, '/prediction', '/predictions', adminAuthenticationService);
const adminAwardApiService: AdminAwardApiService = new AdminAwardApiService(apiBaseUrl, '/award', '/awards', adminAuthenticationService);
const adminApiServiceCommon: AdminApiServiceCommon = new AdminApiServiceCommon(apiBaseUrl, adminAuthenticationService);
const adminSignalAlertApiService: AdminSignalAlertApiService = new AdminSignalAlertApiService(apiBaseUrl, '/analysis/signal/alert', '/analysis/signal/alerts', adminAuthenticationService);
const adminAutoTradeSetupService: AdminAutoTradeSetupService = new AdminAutoTradeSetupService(apiBaseUrl, '/auto-trade/setup', '/auto-trade/setups', adminAuthenticationService);
const adminAutoTradesService: AdminAutoTradesService = new AdminAutoTradesService(apiBaseUrl, '/auto-trade/trade', '/auto-trade/trades', adminAuthenticationService);
const adminAutoTradeStockLimitService: AdminAutoTradeStockLimitService = new AdminAutoTradeStockLimitService(apiBaseUrl, '/auto-trade/symbol/option', '/auto-trade/symbol/options', adminAuthenticationService);

export {
  AdminApiService,
  AdminPredictionApiService,
  adminContestApiService,
  adminLeagueApiService,
  adminLeagueTeamApiService,
  adminLeagueSeasonApiService,
  adminStockHistoryApiService,
  adminPredictionApiService,
  adminAwardApiService,
  adminSignalAlertApiService,
  adminApiServiceCommon,
  adminAutoTradeSetupService,
  adminAutoTradesService,
  adminAutoTradeStockLimitService
}
